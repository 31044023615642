// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
export const firebase={
  apiKey: "AIzaSyD6lv3jew4tWZoem-tXzuQLvB9GGQ_c69E",
  authDomain: "cyberpneumatecnologiasas.firebaseapp.com",
  projectId: "cyberpneumatecnologiasas",
  storageBucket: "cyberpneumatecnologiasas.appspot.com",
  messagingSenderId: "420157423669",
  appId: "1:420157423669:web:4f6e1b0145a96bd856717c",
  measurementId: "G-8DKGEXVNTP"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
